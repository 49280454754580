import styled from "styled-components";
import { Box, Modal } from "@mui/material";

export const SubscriptionCardContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
`;

export const NormalPlanCard = styled.div<{
  disable: boolean;
}>`
  display: block;
  height: 100%;
  max-height: 100%;
  width: 335px;
  position: relative;
  background-color: rgba(27, 125, 255, 0.1);
  color: #000;
  border-radius: 1rem;
  padding: 2.5rem 1.5rem 1.5rem 2.5rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    border: 1.5px solid rgba(15, 110, 255, 1);
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.15)
      )
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

  > img {
    display: block;
    position: absolute;
    top: -8px;
    right: -5px;
  }
  > h3 {
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 24px;
  }
  > div {
    margin: 1.5rem 0 1rem 0;
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 24px;
    > span {
      font-size: 35px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
      line-height: 35.5px;
    }
  }
  > p {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    line-height: 18px;
    margin-bottom: 1.5rem;
    > span {
      text-decoration: underline;
      cursor: pointer;
      position: relative;
      z-index: 1;
    }
  }
  > button {
    &:hover {
      cursor: ${(props) => (props.disable ? "not-allowed" : "pointer")};
    }
    position: relative;
    z-index: 1;
    width: 168px;
    height: 41px;
    border-radius: 10px;
    border: none;
    outline: none;
    background: ${(props) =>
      !props.disable
        ? `linear-gradient(
      to right,
      rgba(0, 102, 255, 1),
      rgba(0, 148, 255, 1)
    )`
        : `linear-gradient(
      to right,
      rgba(0, 102, 255, 0.5),
      rgba(0, 148, 255, 0.5)
    )`};
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 15px;
  }
  > hr {
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin: 1.2rem 0;
  }
  > span {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    line-height: 15px;
    text-decoration: underline;
    margin-bottom: 0.5rem;
    cursor: pointer;
    position: relative;
    z-index: 1;
  }
  > ul {
    list-style: none;
    padding: 0;
    margin-top: 1rem;
    > li {
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 15px;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin: 0.8rem 0;
      > img {
      }
    }
  }
`;

export const CustomPlanCard = styled.div`
  height: 100%;
  max-height: 100%;
  width: 335px;
  position: relative;
  background-color: rgba(27, 125, 255, 0.1);
  color: #000;
  border-radius: 1rem;
  padding: 2.5rem 1.5rem 1.5rem 2.5rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    border: 1.5px solid rgba(15, 110, 255, 1);
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.15)
      )
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

  > img {
    position: absolute;
    top: -8px;
    right: -5px;
    display: none;
  }
  > h3 {
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 24px;
  }
  > div {
    margin: 1.5rem 0 1rem 0;
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 24px;
    > span {
      font-size: 35px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
      line-height: 35.5px;
    }
  }
  > p {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    line-height: 18px;
    margin-bottom: 1.5rem;
    > span {
      text-decoration: underline;
      cursor: pointer;
      position: relative;
      z-index: 1;
    }
  }
  > button {
    position: relative;
    z-index: 1;
    width: 168px;
    height: 41px;
    border-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    background: linear-gradient(
      to right,
      rgba(0, 102, 255, 1),
      rgba(0, 148, 255, 1)
    );
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 15px;
  }
  > hr {
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin: 1.2rem 0;
  }
  > span {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    line-height: 15px;
    text-decoration: underline;
    margin-bottom: 0.5rem;
    cursor: pointer;
    position: relative;
    z-index: 1;
  }
  > ul {
    list-style: none;
    padding: 0;
    margin-top: 1rem;
    > li {
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 15px;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin: 0.8rem 0;
      > img {
      }
    }
  }
`;

export const OverageChargesModal = styled(Modal)``;
export const SubscriptionUpgradeModal = styled(Modal)``;

export const ModalBox = styled(Box)`
  outline: none;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  height: max-content;
  width: 533px;
  background: linear-gradient(
    to right,
    rgba(17, 50, 148, 1),
    rgba(0, 28, 108, 1)
  );
  color: #fff;
  border-radius: 15px;
  padding: 1.5rem;
`;

export const SubscriptionModalBox = styled(Box)`
  outline: none;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  height: 350px;
  width: 533px;
  background: linear-gradient(
    to right,
    rgba(17, 50, 148, 1),
    rgba(0, 28, 108, 1)
  );
  color: #fff;
  border-radius: 15px;
  padding: 1.5rem;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(255, 255, 255, 1);
  }
  > img {
    cursor: pointer;
    position: relative;
    z-index: 1;
  }
`;

export const OverageChargesTableContainer = styled.div`
  height: max-content;
  width: 497px;
  position: relative;
  background-color: rgba(30, 30, 30, 0.15);
  color: #fff;
  border-radius: 1rem;
  padding: 2rem;
  margin: 2rem 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    border: 1px solid transparent;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.15)
      )
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
`;

export const SubsAdAccount = styled(Modal)``;

export const SubsAdAccountBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: 533px;
  height: max-content;
  border: 1px solid rgba(15, 110, 255, 1);
  background: linear-gradient(
    to right,
    rgba(17, 50, 148, 1),
    rgba(0, 28, 108, 1)
  );
  color: #000;
  border-radius: 15px;
  padding: 1.5rem;
`;

export const AdAccountName = styled.li`
  color: white;
`;

export const AdAccountNameList = styled.ul`
  margin: 1rem 0 !important;
`;

export const SubscriptionModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  & > img {
    margin: 2rem 0 2rem 0;
    width: 125px;
  }

  & > h4 {
    font-size: 20px;
  }

  & > p {
    font-size: 14px;
    margin-top: 0.4rem;
  }
`;
