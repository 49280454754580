import {
  AdAccountName,
  AdAccountNameList,
  CustomPlanCard,
  ModalBox,
  ModalHeader,
  NormalPlanCard,
  OverageChargesModal,
  OverageChargesTableContainer,
  SubsAdAccount,
  SubsAdAccountBox,
  SubscriptionCardContainer,
  SubscriptionModalBox,
  SubscriptionModalContainer,
  SubscriptionUpgradeModal,
} from "./SubscriptionCard.styles";
import SearchIcon from "@mui/icons-material/Search";
import CircleTickIcon from "../../assets/tick-circle.svg";
import { useEffect, useMemo, useState } from "react";
import CloseCircleIcon from "../../assets/close-circle.svg";
import OverageChargesDataTable from "../Table/OverageCharges/DataTable";
import {
  OverageChargesData,
  OverageChargesUSDData,
} from "./OverageChargesData";
import {
  useActiveUserSubscriptionMutation,
  useCreateSubscriptionStripeMutation,
  useGetSubscriptionLinkMutation,
  useGetZohoCustomerMutation,
  useSubscriptionRenewMutation,
  useUpdateSubscriptionMutation,
  useUpdateSubscriptionStripeMutation,
} from "src/api/subscription";
import { useSnackbar } from "notistack";
import { useAppSelector } from "src/hooks/useAppSelector";
import PaymentSuccessImg from "../../assets/payment-success.svg";
import PaymentSuccessStar1 from "../../assets/PaymentSuccessStar1.svg";
import PaymentSuccessStar2 from "../../assets/PaymentSuccessStar2.svg";
import PaymentSuccessStar3 from "../../assets/PaymentSuccessStar3.svg";
import PaymentSuccessStar4 from "../../assets/PaymentSuccessStar4.svg";
import PaymentFailedImg from "../../assets/PaymentFailedImg.svg";
import {
  CrossIconContainer,
  FailedBoxBottomContainer,
  FailedBoxTopContainer,
  ModalFailedBox,
  ModalSuccessBox,
  PaymentFailedContainer,
  PaymentSuccessContainer,
  SuccessBoxBottomContainer,
  SuccessBoxTopContainer,
} from "../walletDropdown/WalletDropDown.styles";
import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { useLocation } from "react-router-dom";
import { SelectAdAccountDropdown } from "src/layout/Layout.styles";
import {
  fetchUserProfile,
  saveFbDetails,
  setActiveAdAccount,
  setIsAdAccountChanging,
} from "src/slices/user/userSlice";
import {
  clearPrevAfter,
  setComparison,
} from "src/slices/allcampaignInsight/allCamapignInsightSlice";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import GreenTick from "../../assets/Tick.png";

type Props = {
  data: any;
  switcher: any;
  activePlan: any;
  allAdAccount: any;
  setChangeAdAccountModal: any;
  setAdAccountPopper: any;
  setSelectedAdAccount: any;
  setCurrentAdAccount: any;
  setIsComparison: any;
};

const SubscriptionCard = ({
  data,
  switcher,
  activePlan,
  allAdAccount,
  setChangeAdAccountModal,
  setAdAccountPopper,
  setSelectedAdAccount,
  setCurrentAdAccount,
  setIsComparison,
}: Props) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isShowingOveragesModal, setIsShowingOveragesModal] = useState(false);
  const [showSubscriptionUpdateModal, setShowSubscriptionUpgradeModal] =
    useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [getZohoCustomer] = useGetZohoCustomerMutation();
  const [getSubscriptionLink] = useGetSubscriptionLinkMutation();
  const [activeUserSubscription] = useActiveUserSubscriptionMutation();
  const [updateSubscription] = useUpdateSubscriptionMutation();
  const [subscriptionRenew] = useSubscriptionRenewMutation();
  const [createSubscriptionStripe] = useCreateSubscriptionStripeMutation();
  const [updateSubscriptionStripe] = useUpdateSubscriptionStripeMutation();
  const user = useAppSelector((state) => state.user.user);
  const [isSubscriptionSuccess, setIsSubscriptionSuccess] = useState(false);
  const [isSubscriptionFailed, setIsSubscriptionFailed] = useState(false);
  const [isNormalPlanLoading, setIsNormalPlanLoading] = useState(false);
  const [successResult, setSuccessResult] = useState<any>();
  const [adAccountSearchTerm, setAdAccountSearchTerm] = useState("");
  const [showingListOfAdAccount, setShowingListOfAdAccount] = useState(false);

  const filteredAdAccountList = useMemo(() => {
    if (allAdAccount && allAdAccount.length > 0) {
      if (adAccountSearchTerm === "") {
        const tmp = [...allAdAccount];
        return tmp.sort((b, a) =>
          b?.ad_account_name
            ?.toLowerCase()
            .localeCompare(a?.ad_account_name.toLowerCase())
        );
      }
      const tmp = [...allAdAccount];
      return tmp
        .filter((item) => {
          return (
            item.ad_account_name
              .toLowerCase()
              .includes(adAccountSearchTerm.toLowerCase()) ||
            item.ad_account_id.includes(adAccountSearchTerm)
          );
        })
        .sort((b, a) =>
          b?.ad_account_name
            ?.toLowerCase()
            .localeCompare(a?.ad_account_name.toLowerCase())
        );
    }
  }, [adAccountSearchTerm, allAdAccount]);

  const change_ad_account = (account: any) => {
    setChangeAdAccountModal(true);
    if (
      user.data.fb_ad_account === account?.ad_account_id &&
      user.data.currency !== "" &&
      user.data.currency !== null
    ) {
      String(user.data.currency).toLowerCase() === "inr"
        ? handleZohoSubscription()
        : handleStripePlan();
      setChangeAdAccountModal(false);
      setShowingListOfAdAccount(false);
    } else {
      if (
        account?.ad_account_name === "" ||
        account?.ad_account_name === "-" ||
        account?.ad_account_name === null ||
        !account?.ad_account_name
      ) {
        enqueueSnackbar("Ad Account Name is required", {
          variant: "error",
        });
        return;
      } else if (
        account?.ad_account_id === "" ||
        account?.ad_account_id === "-" ||
        account?.ad_account_id === null ||
        !account?.ad_account_id
      ) {
        enqueueSnackbar("Ad Account Id is required", {
          variant: "error",
        });
        return;
      } else {
        dispatch(setIsAdAccountChanging(true));
        setAdAccountPopper(false);
        setSelectedAdAccount(account);
        setCurrentAdAccount(account);
        dispatch(clearPrevAfter());
        dispatch(setComparison(false));

        dispatch(
          saveFbDetails({
            account_status: account.account_status,
            ad_account_id: account?.ad_account_id,
            ad_account_name: account?.ad_account_name,
            app_fb_user_id: user?.data?.app_fb_user_id,
            currency: account.currency,
            currency_offset: account.currency_offset,
            disable_reason: account.disable_reason,
            disable_reason_display_string:
              account.disable_reason_display_string,
            euid: String(user?.data?.euid),
            min_daily_budget: account.min_daily_budget,
            timezone_id: account.timezone_id,
            timezone_offset_hours_utc: account.timezone_offset_hours_utc,
            timezone_name: account.timezone_name,
          })
        )
          .unwrap()
          .then((res) => {
            dispatch(setActiveAdAccount(account));
            dispatch(fetchUserProfile({ email: user?.data?.email }));
            setChangeAdAccountModal(false);
            dispatch(setIsAdAccountChanging(false));
            dispatch(clearPrevAfter());
            setIsComparison(false);
          })
          .then(() => {
            if (user.data.currency === "" || user.data.currency === null) {
              handleZohoSubscription();
            } else if (user.data.currency) {
              String(user.data.currency).toLowerCase() === "inr"
                ? handleZohoSubscription()
                : handleStripePlan();
            } else {
              handleZohoSubscription();
            }
          });
        setShowingListOfAdAccount(false);
      }
    }
  };

  const handleModal = (openModal: any) => {
    setShowingListOfAdAccount(openModal);
  };

  useEffect(() => {
    if (!location.search) return;
    var query = location.search.substr(1);
    var result: any = {};
    query.split("&").forEach(function (part: any) {
      var item: any = part.split("=");
      result[item[0]] = decodeURIComponent(item[1]);
    });
    setSuccessResult(result);
    if (result.tid) {
      setIsSubscriptionSuccess(true);
    } else {
      setIsSubscriptionFailed(true);
    }
  }, [location.search]);

  const handleStripePlan = async () => {
    setIsNormalPlanLoading(true);
    try {
      await activeUserSubscription({
        euid: user.data.euid,
        ad_account_id: user.data.fb_ad_account ? user.data.fb_ad_account : "",
      })
        .unwrap()
        .then(async (activeuserSubscriptionRes: any) => {
          const todayDate: any = new Date();
          const subscriptionExpiryData: any = new Date(
            activeuserSubscriptionRes[0]?.expiry_at
          );
          const differenceInMs = subscriptionExpiryData - todayDate;
          const differenceInDays = differenceInMs / (1000 * 3600 * 24);
          if (activeuserSubscriptionRes.length > 0 && differenceInDays >= 5) {
            try {
              await updateSubscriptionStripe({
                ad_account_id: user.data.fb_ad_account
                  ? user.data.fb_ad_account
                  : "",
                amount: 0,
                email: user.data.email,
                euid: user.data.euid,
                plan_id: data.plan_id,
                subscription_id: activeuserSubscriptionRes[0].subscription_id,
                tax: 0,
                total_amount: data.usd_amount,
              })
                .unwrap()
                .then((response: any) => {
                  if (activePlan) {
                    if (
                      Number(activePlan.price_range) > Number(data.price_range)
                    ) {
                      enqueueSnackbar(
                        `Your plan will be downgraded on  ${
                          activePlan.expires_date &&
                          format(
                            parseISO(activePlan.expires_date),
                            "dd MMM yyyy"
                          )
                        }`,
                        {
                          variant: "success",
                        }
                      );
                    } else {
                      if (response.payment_link) {
                        window.open(response.payment_link, "_self");
                      } else {
                        setShowSubscriptionUpgradeModal(true);
                      }
                    }
                  } else {
                    enqueueSnackbar("You have been updated to the new plan.", {
                      variant: "success",
                    });
                  }
                  setIsNormalPlanLoading(false);
                });
            } catch (e: any) {
              console.log(e);
              if (e.data.message) {
                enqueueSnackbar(e.data.message, {
                  variant: "error",
                });
              } else {
                enqueueSnackbar("Something went wrong", {
                  variant: "error",
                });
              }
              setIsNormalPlanLoading(false);
            }
          } else {
            try {
              await createSubscriptionStripe({
                ad_account_id: user.data.fb_ad_account
                  ? user.data.fb_ad_account
                  : "",
                amount: 0,
                email: user.data.email,
                euid: user.data.euid,
                plan_id: data.plan_id,
                subscription_id: "",
                tax: 0,
                total_amount: data.usd_amount,
              })
                .unwrap()
                .then((response: any) => {
                  window.open(response.payment_link, "_self");
                  setIsNormalPlanLoading(false);
                });
              enqueueSnackbar("Generate payment link successful", {
                variant: "success",
              });
            } catch (e: any) {
              console.log(e);
              if (e.data.message) {
                enqueueSnackbar(e.data.message, {
                  variant: "error",
                });
              } else {
                enqueueSnackbar("Something went wrong", {
                  variant: "error",
                });
              }
              setIsNormalPlanLoading(false);
            }
          }
        });
    } catch (e: any) {
      console.log(e);
      if (e.data.message) {
        enqueueSnackbar(e.data.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      }
      setIsNormalPlanLoading(false);
    }
  };

  const handleZohoSubscription = async () => {
    setIsNormalPlanLoading(true);
    try {
      await activeUserSubscription({
        euid: user.data.euid,
        ad_account_id: user.data.fb_ad_account ? user.data.fb_ad_account : "",
      })
        .unwrap()
        .then(async (activeuserSubscriptionRes: any) => {
          const todayDate: any = new Date();
          const subscriptionExpiryData: any = new Date(
            activeuserSubscriptionRes[0]?.expiry_at
          );
          const differenceInMs = subscriptionExpiryData - todayDate;
          const differenceInDays = differenceInMs / (1000 * 3600 * 24);
          if (activeuserSubscriptionRes.length > 0 && differenceInDays >= 5) {
            try {
              await getZohoCustomer({
                euid: user.data.euid,
              })
                .unwrap()
                .then(async (response: any) => {
                  if (response.customer_id) {
                    try {
                      await updateSubscription({
                        euid: user.data.euid,
                        ad_account_id: user.data.fb_ad_account
                          ? user.data.fb_ad_account
                          : "",
                        plan_id: data.plan_id,
                        subscription_id:
                          activeuserSubscriptionRes[0].subscription_id,
                      })
                        .unwrap()
                        .then((response: any) => {
                          if (activePlan) {
                            if (
                              Number(activePlan?.price_range) >
                              Number(data?.price_range)
                            ) {
                              enqueueSnackbar(
                                `Your plan will be downgraded on  ${
                                  activePlan.expires_date &&
                                  format(
                                    parseISO(activePlan.expires_date),
                                    "dd MMM yyyy"
                                  )
                                }`,
                                {
                                  variant: "success",
                                }
                              );
                            } else {
                              window.open(response.payment_link, "_self");
                              enqueueSnackbar(
                                "Generate payment link successful",
                                {
                                  variant: "success",
                                }
                              );
                            }
                          } else {
                            window.open(response.payment_link, "_self");
                            enqueueSnackbar(
                              "Generate payment link successful",
                              {
                                variant: "success",
                              }
                            );
                          }
                          setIsNormalPlanLoading(false);
                        });
                    } catch (e: any) {
                      console.log(e);
                      if (e.data.message) {
                        enqueueSnackbar(e.data.message, {
                          variant: "error",
                        });
                      } else {
                        enqueueSnackbar("Something went wrong", {
                          variant: "error",
                        });
                      }
                      setIsNormalPlanLoading(false);
                    }
                  } else {
                    enqueueSnackbar("Something went wrong", {
                      variant: "error",
                    });
                    setIsNormalPlanLoading(false);
                  }
                });
            } catch (e: any) {
              console.log(e);
              if (e.data.message) {
                enqueueSnackbar(e.data.message, {
                  variant: "error",
                });
              } else {
                enqueueSnackbar("Something went wrong", {
                  variant: "error",
                });
              }
              setIsNormalPlanLoading(false);
            }
          } else {
            try {
              await getZohoCustomer({
                euid: user.data.euid,
              })
                .unwrap()
                .then(async (response: any) => {
                  if (response.customer_id) {
                    try {
                      await getSubscriptionLink({
                        euid: user.data.euid,
                        ad_account_id: user.data.fb_ad_account
                          ? user.data.fb_ad_account
                          : "",
                        plan_id: data.plan_id,
                      })
                        .unwrap()
                        .then((response: any) => {
                          window.open(response.payment_link, "_self");
                          setIsNormalPlanLoading(false);
                        });
                      enqueueSnackbar("Generate payment link successful", {
                        variant: "success",
                      });
                    } catch (e: any) {
                      console.log(e);
                      if (e.data.message) {
                        enqueueSnackbar(e.data.message, {
                          variant: "error",
                        });
                      } else {
                        enqueueSnackbar("Something went wrong", {
                          variant: "error",
                        });
                      }
                      setIsNormalPlanLoading(false);
                    }
                  } else {
                    enqueueSnackbar("Something went wrong", {
                      variant: "error",
                    });
                    setIsNormalPlanLoading(false);
                  }
                });
            } catch (e: any) {
              console.log(e);
              if (e.data.message) {
                enqueueSnackbar(e.data.message, {
                  variant: "error",
                });
              } else {
                enqueueSnackbar("Something went wrong", {
                  variant: "error",
                });
              }
              setIsNormalPlanLoading(false);
            }
          }
        });
    } catch (e: any) {
      console.log(e);
      if (e.data.message) {
        enqueueSnackbar(e.data.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      }
      setIsNormalPlanLoading(false);
    }
  };

  function handleRedirectionToDemo() {
    window.open("https://ad360.ai/book-a-demo/");
  }

  return (
    <>
      <SubsAdAccount
        open={showingListOfAdAccount}
        onClose={() => setShowingListOfAdAccount(false)}
      >
        <SubsAdAccountBox>
          <SelectAdAccountDropdown>
            <TextField
              fullWidth
              size="small"
              placeholder="Search by ad account name or ID..."
              value={adAccountSearchTerm}
              onChange={(e) => setAdAccountSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <AdAccountNameList>
              {filteredAdAccountList?.length !== 0 &&
                filteredAdAccountList?.map((item) => {
                  return (
                    <AdAccountName
                      onClick={() => change_ad_account(item)}
                      key={item.ad_account_id}
                    >
                      <p>{item.ad_account_name || "-"}</p>
                      <span>{item.ad_account_id || "-"}</span>
                    </AdAccountName>
                  );
                })}
              {filteredAdAccountList?.length === 0 && (
                <p>No ad account to show!</p>
              )}
            </AdAccountNameList>
          </SelectAdAccountDropdown>
        </SubsAdAccountBox>
      </SubsAdAccount>
      <SubscriptionCardContainer>
        <NormalPlanCard
          disable={
            activePlan &&
            new Date(activePlan?.expires_date) > new Date() &&
            data?.price_range === activePlan?.price_range
          }
        >
          <img src={CircleTickIcon} alt="circle-tick-icon" />
          <h3>Plan</h3>
          <div>
            {switcher ? "₹" : "$"}
            <span>{switcher ? data?.amount : data?.usd_amount}</span>
          </div>
          <p>
            <span onClick={() => setIsShowingOveragesModal(true)}>
              Overages
            </span>{" "}
            apply if ad spend limit is exceeded
          </p>
          <button
            onClick={() => handleModal(true)}
            disabled={
              (activePlan &&
                data?.price_range === activePlan?.price_range &&
                new Date(activePlan?.expires_date) > new Date()) ||
              isNormalPlanLoading
            }
          >
            {isNormalPlanLoading ? (
              <CircularProgress style={{ width: "1.6rem", height: "1.6rem" }} />
            ) : activePlan &&
              new Date(activePlan?.expires_date) > new Date() ? (
              data?.price_range === activePlan?.price_range ? (
                "Active Plan"
              ) : Number(data?.price_range) <
                Number(activePlan?.price_range) ? (
                "Downgrade"
              ) : Number(data?.price_range) >
                Number(activePlan?.price_range) ? (
                "Upgrade"
              ) : (
                "Proceed with this"
              )
            ) : (
              "Proceed with this"
            )}
          </button>
        </NormalPlanCard>

        <CustomPlanCard>
          <img src={CircleTickIcon} alt="circle-tick-icon" />
          <h3>Higher ad spends ?</h3>
          <div>
            <span>Custom Plan</span>
          </div>
          <p>
            <span>
              <br />
            </span>
          </p>
          <button onClick={handleRedirectionToDemo}>Talk to us</button>
        </CustomPlanCard>

        <OverageChargesModal
          open={isShowingOveragesModal}
          onClose={() => setIsShowingOveragesModal(false)}
        >
          <ModalBox>
            <ModalHeader>
              <h3>Overage Charges</h3>
              <img
                src={CloseCircleIcon}
                alt="circle-cross-icon"
                onClick={() => setIsShowingOveragesModal(false)}
              />
            </ModalHeader>
            <OverageChargesTableContainer>
              <OverageChargesDataTable
                data={
                  String(user?.data?.currency).toLowerCase() === "inr" ||
                  user?.data?.currency === "" ||
                  user?.data?.currency === null
                    ? activePlan && activePlan !== null
                      ? activePlan?.amount.toString() === "8299" &&
                        new Date(activePlan?.expires_date) > new Date()
                        ? OverageChargesData
                        : OverageChargesData.filter(
                            (data) => data.plans !== "₹ 8,299"
                          )
                      : OverageChargesData.filter(
                          (data) => data.plans !== "₹ 8,299"
                        )
                    : activePlan &&
                      activePlan !== null &&
                      new Date(activePlan?.expires_date) > new Date()
                    ? OverageChargesUSDData.filter(
                        (data) =>
                          Number(data.amount)! >= Number(activePlan.amount)
                      )
                    : OverageChargesUSDData
                }
                switcher={switcher}
              />
            </OverageChargesTableContainer>
          </ModalBox>
        </OverageChargesModal>

        <SubscriptionUpgradeModal
          open={showSubscriptionUpdateModal}
          onClose={() => setShowSubscriptionUpgradeModal(false)}
        >
          <SubscriptionModalBox>
            <ModalHeader>
              <h3></h3>
              <img
                src={CloseCircleIcon}
                alt="circle-cross-icon"
                onClick={() => setShowSubscriptionUpgradeModal(false)}
              />
            </ModalHeader>
            <SubscriptionModalContainer>
              <img src={GreenTick} alt="green-circle-tick-img" />
              <h4>You have been updated to the new plan.</h4>
              <p>You can start using the your current plan features.</p>
            </SubscriptionModalContainer>
          </SubscriptionModalBox>
        </SubscriptionUpgradeModal>
      </SubscriptionCardContainer>

      {isSubscriptionSuccess && (
        <PaymentSuccessContainer
          open={isSubscriptionSuccess}
          onClose={() => window.location.replace("/subscription")}
        >
          <ModalSuccessBox>
            <CrossIconContainer>
              <img
                src={CloseCircleIcon}
                alt="cross-icon"
                onClick={() => window.location.replace("/subscription")}
              />
            </CrossIconContainer>
            <SuccessBoxTopContainer>
              <img src={PaymentSuccessImg} alt="payment-success-img" />
              <img src={PaymentSuccessStar1} alt="star-img" />
              <img src={PaymentSuccessStar2} alt="star-img" />
              <img src={PaymentSuccessStar3} alt="star-img" />
              <img src={PaymentSuccessStar4} alt="star-img" />
            </SuccessBoxTopContainer>
            <SuccessBoxBottomContainer>
              <h2>Subscribed Successful!</h2>
              {successResult && successResult.planname ? (
                <h4>Plan Name: {successResult.planname}</h4>
              ) : null}
              {successResult && successResult.planname ? (
                <p>Next Billing Date: {successResult.nextbilldate}</p>
              ) : null}
              {/* <p>Payment IDL 7686987, 30 Nov 2023 - 12:05 PM</p> */}
            </SuccessBoxBottomContainer>
          </ModalSuccessBox>
        </PaymentSuccessContainer>
      )}
      {isSubscriptionFailed && (
        <PaymentFailedContainer
          open={isSubscriptionFailed}
          onClose={() => window.location.replace("/subscription")}
        >
          <ModalFailedBox>
            <CrossIconContainer>
              <img
                src={CloseCircleIcon}
                alt="cross-icon"
                onClick={() => window.location.replace("/subscription")}
              />
            </CrossIconContainer>
            <FailedBoxTopContainer>
              <img src={PaymentFailedImg} alt="payment-success-img" />
            </FailedBoxTopContainer>
            <FailedBoxBottomContainer>
              <h2>Subscription Failed</h2>
              {/* <p>Payment IDL 7686987, 30 Nov 2023 - 12:05 PM</p> */}
              {/* <button>
                <img src={RetryIcon} alt="retry-icon" />
                <span>Retry</span>
              </button> */}
            </FailedBoxBottomContainer>
          </ModalFailedBox>
        </PaymentFailedContainer>
      )}
    </>
  );
};

export default SubscriptionCard;
