import { Slider } from "@mui/material";
import styled from "styled-components";

export const SubscriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 200px;

  margin-top: 3rem;
  > h4 {
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }

  > h2 {
    font-size: 24px;
    line-height: 20px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    margin-top: 1.5rem;

    > span {
      margin-left: 1rem;
      color: rgba(255, 168, 0, 1);
      font-weight: 600;
      text-decoration: underline;
    }
  }
`;

export const SliderContainer = styled(Slider)`
  margin-top: 2rem;
`;

export const SwitchContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  > img {
    position: relative;
    top: -8px;
    right: 12px;
  }
`;

export const SwitchContainerText1 = styled.span<{ isSelected: boolean }>`
  color: ${(props) =>
    props.isSelected ? "rgba(255, 255, 255, 0.5)" : "white"};
  font-size: 14px;
  font-weight: 400;
`;

export const SwitchContainerText2 = styled.span<{ isSelected: boolean }>`
  color: ${(props) =>
    props.isSelected ? "white" : "rgba(255, 255, 255, 0.5)"};
  font-size: 14px;
  font-weight: 400;
`;

export const ExcludingGSTText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: white;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

export const CircularProgressContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;
