import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import {
  AccountDetails,
  AccountSpendingLimit,
  BillingDetails,
  BillingModal,
  BillingModalBox,
  ChangeAccountDetailsModal,
  ChangeAccountDetailsModalBox,
  DisplayFlexContainer,
  FbDetails,
  InputTextField,
  MyPlan,
  NoAccountSpendingLimit,
  NoBillingDetails,
  Notification,
  PlanPriceContainer,
  ProfileContainer,
  SettingsContainer,
} from "./Settings.styles";
import AvatarImg from "../../assets/avatar.png";
import PlanImg from "../../assets/PlanExp.svg";
import { Switch, styled } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import CrossCircleImg from "../../assets/close-circle.svg";
import ProfileImg from "../../assets/avatar.png";
import FBAdPage from "src/components/FBAdPage/FBAdPage";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import { changeName } from "src/slices/user/userSlice";
import { useGetUserSubscriptionDetailsQuery } from "src/api/subscription";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import {
  useGetBillingDetailsQuery,
  useUpdateBillingDetailsMutation,
} from "src/api/user";
import NoBillingDetailsImg from "../../assets/NoBillingDetails.svg";
import { useGetAdsSpendBalanceQuery } from "src/api/wallet-balance";
import ReauthenticateFb from "src/components/ReauthenticateFb/ReauthenticateFb";
import { fetchSymbolFromCurrency } from "src/libs/utils";

type Props = {};

const Settings = (props: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const user = useAppSelector((state: any) => state.user);
  const dispatch = useAppDispatch();
  const [switcher, setSwitcher] = useState(true);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [name, setName] = useState("");
  const nameref = useRef<any>(null);
  const [isInputEditable, setIsInputEditable] = useState(false);

  const userLocation = useAppSelector(
    (state) => state.userLocation.userLocation
  );

  const [showChangeAccountDetailsModal, setShowChangeAccountDetailsModal] =
    useState(false);

  const { data, isLoading, isSuccess, isError } =
    useGetUserSubscriptionDetailsQuery({
      ad_account: user?.user?.data?.fb_ad_account
        ? user?.user?.data?.fb_ad_account
        : "",
      euid: user?.user?.data?.euid,
    });

  const {
    data: billingDetails,
    isLoading: isBillingDetailsLoading,
    isSuccess: isBillingDetailsSuccess,
    isError: isBillingDetailsError,
  } = useGetBillingDetailsQuery(
    {
      euid: user?.user?.data?.euid,
    },
    { skip: userLocation ? userLocation !== "IN" : false }
  );

  console.log(userLocation);

  const [updateBillingDetails] = useUpdateBillingDetailsMutation();

  const {
    data: adspendBalance,
    isLoading: isAdspendBalanceLoading,
    isError: isAdspendBalanceError,
    isSuccess: isAdspendBalanceSuccess,
  } = useGetAdsSpendBalanceQuery({
    ad_account: user?.user?.data?.fb_ad_account
      ? user?.user?.data?.fb_ad_account
      : "",
    euid: user?.user?.data?.euid,
  });

  const [billingData, setBillingData] = useState({
    gstNo: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    contact: "",
  });

  useEffect(() => {
    setBillingData({
      gstNo: isBillingDetailsLoading
        ? "Loading..."
        : isBillingDetailsSuccess
        ? billingDetails.gst_number
        : "",
      streetAddress: isBillingDetailsLoading
        ? "Loading..."
        : isBillingDetailsSuccess
        ? billingDetails.streetAddress
        : "",
      city: isBillingDetailsLoading
        ? "Loading..."
        : isBillingDetailsSuccess
        ? billingDetails.city
        : "",
      state: isBillingDetailsLoading
        ? "Loading..."
        : isBillingDetailsSuccess
        ? billingDetails.state
        : "",
      zipCode: isBillingDetailsLoading
        ? "Loading..."
        : isBillingDetailsSuccess
        ? billingDetails.zipcode
        : "",
      contact: isBillingDetailsLoading
        ? "Loading..."
        : isBillingDetailsSuccess
        ? billingDetails.contact
        : "",
    });
  }, [isBillingDetailsLoading, isBillingDetailsSuccess]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setBillingData((prevData) => {
      return { ...prevData, [name]: value };
    });
  };

  useEffect(() => {
    if (isInputEditable) {
      nameref.current.focus();
    }
  }, [isInputEditable]);

  useEffect(() => {
    setName(user?.user.data?.business_name);
  }, [user.loading]);

  const handleSaveName = () => {
    if (name.length === 0) {
      enqueueSnackbar("Name field should be filled", {
        variant: "error",
      });
      nameref.current.focus();
    } else if (name === user.user.data.business_name) {
      setIsInputEditable(false);
    } else if (name.length > 0) {
      setIsInputEditable(false);
      dispatch(
        changeName({
          euid: user.user.data.euid,
          newName: name,
        })
      )
        .then(() => {
          enqueueSnackbar("Name successfully changed", {
            variant: "success",
          });
        })
        .catch((e) => {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
        });
    }
  };

  const handleUpdateBillingDetails = async () => {
    if (
      !billingData.gstNo &&
      !billingData.streetAddress &&
      !billingData.city &&
      !billingData.state &&
      !billingData.zipCode &&
      !billingData.contact
    ) {
      enqueueSnackbar("All Fields are mandatory to be filled", {
        variant: "error",
      });
    } else if (!billingData.gstNo) {
      enqueueSnackbar("Please fill the gst number field", {
        variant: "error",
      });
    } else if (!billingData.streetAddress) {
      enqueueSnackbar("Please fill the street address field", {
        variant: "error",
      });
    } else if (!billingData.city) {
      enqueueSnackbar("Please fill the city field", {
        variant: "error",
      });
    } else if (!billingData.state) {
      enqueueSnackbar("Please fill the state field", {
        variant: "error",
      });
    } else if (!billingData.zipCode) {
      enqueueSnackbar("Please fill the zip code field", {
        variant: "error",
      });
    } else if (!billingData.contact) {
      enqueueSnackbar("Please fill the contact field", {
        variant: "error",
      });
    }
    if (
      billingData.gstNo &&
      billingData.streetAddress &&
      billingData.city &&
      billingData.state &&
      billingData.zipCode &&
      billingData.contact
    ) {
      try {
        await updateBillingDetails({
          city: billingData.city,
          euid: user?.user?.data?.euid,
          gst_number: billingData.gstNo,
          state: billingData.state,
          streetAddress: billingData.streetAddress,
          zipcode: billingData.zipCode,
          contact: billingData.contact,
        }).unwrap();
        enqueueSnackbar("Billing details updated successful", {
          variant: "success",
        });
        setShowBillingModal(false);
      } catch (e) {
        console.log(e);
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    }
  };

  const adspendProgress = isAdspendBalanceSuccess
    ? (adspendBalance.spend * 100) / adspendBalance.spend_cap
    : "0";

  const adspendProgressValue = adspendProgress
    ? Number(adspendProgress) > 100
      ? 100
      : adspendProgress
    : 0;

  return (
    <SettingsContainer>
      {user && (
        <AccountDetails>
          <h4>Account details</h4>
          <div>
            <div>
              <div>
                <img src={AvatarImg} alt="user-img" />
              </div>
              <div>
                <h3>{name}</h3>
                <h5>{user.loading ? "Loading..." : user?.user.data?.email}</h5>
              </div>
            </div>
            <div>
              <span>{user.currentAdAccount.ad_account_name?.slice(0, 1)}</span>
              <div>
                <h5>{user.currentAdAccount.ad_account_name}</h5>
                <p>Account ID: {user.currentAdAccount.ad_account_id}</p>
              </div>
            </div>
            <button onClick={() => setShowChangeAccountDetailsModal(true)}>
              Edit
            </button>
          </div>
        </AccountDetails>
      )}

      <FbDetails>
        <h4>Fb Details</h4>
        <div>
          {user &&
          user.user &&
          (user.user.data.reauthenticate_flag === 1 ||
            !user.user.data.page_id) ? (
            <ReauthenticateFb />
          ) : (
            <a
              href={`https://www.facebook.com/${user.user.data?.page_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View
            </a>
          )}
        </div>
      </FbDetails>

      {user.user.data?.currency ? (
        String(user.user.data.currency).toLowerCase() === "inr" ? (
          isBillingDetailsError ||
          (isBillingDetailsSuccess && isBillingDetailsError) ||
          (isBillingDetailsSuccess &&
            !billingDetails.gst_number &&
            !billingDetails.streetAddress &&
            !billingDetails.city &&
            !billingDetails.state &&
            !billingDetails.zipcode) ? (
            <NoBillingDetails>
              <h4>Billing Details</h4>
              <div>
                <div>
                  <img src={NoBillingDetailsImg} alt="no-billing-details-img" />
                  <h5>No Details Found</h5>
                </div>
                <button onClick={() => setShowBillingModal(true)}>
                  Add Details
                </button>
              </div>
            </NoBillingDetails>
          ) : (
            <BillingDetails>
              <h4>Billing Details</h4>
              <div>
                <div>
                  <h6>GST Number</h6>
                  <p>
                    {isBillingDetailsLoading
                      ? "Loading..."
                      : isBillingDetailsSuccess
                      ? billingDetails.gst_number
                      : ""}
                  </p>
                </div>
                <div>
                  <h6>Address</h6>
                  <p>
                    {isBillingDetailsLoading
                      ? "Loading..."
                      : isBillingDetailsSuccess
                      ? billingDetails.streetAddress
                      : ""}{" "}
                    ,
                    <br />{" "}
                    {isBillingDetailsLoading
                      ? "Loading..."
                      : isBillingDetailsSuccess
                      ? billingDetails.city
                      : ""}
                    ,{" "}
                    {isBillingDetailsLoading
                      ? "Loading..."
                      : isBillingDetailsSuccess
                      ? billingDetails.state
                      : ""}
                    ,{" "}
                    {isBillingDetailsLoading
                      ? "Loading..."
                      : isBillingDetailsSuccess
                      ? billingDetails.zipcode
                      : ""}
                  </p>
                </div>
                <div>
                  <h6>Contact</h6>
                  <p>
                    {isBillingDetailsLoading
                      ? "Loading..."
                      : isBillingDetailsSuccess
                      ? billingDetails.contact
                      : ""}
                  </p>
                </div>
                <button onClick={() => setShowBillingModal(true)}>Edit</button>
              </div>
            </BillingDetails>
          )
        ) : (
          ""
        )
      ) : (
        ""
      )}

      <AccountSpendingLimit>
        <h4>Account spending limit</h4>
        {isAdspendBalanceLoading ? (
          <NoAccountSpendingLimit>
            <p>Loading...</p>
          </NoAccountSpendingLimit>
        ) : isAdspendBalanceSuccess ? (
          <div>
            <h3>
              Remaining amount:{" "}
              {user.user.data.currency
                ? String(user.user.data.currency).toLowerCase() === "" ||
                  String(user.user.data.currency).toLowerCase() === null
                  ? "₹"
                  : fetchSymbolFromCurrency(user.user.data.currency)
                : "₹"}{" "}
              {isAdspendBalanceLoading
                ? "Loading..."
                : isAdspendBalanceSuccess
                ? adspendBalance.balance.toLocaleString()
                : "0"}
            </h3>
            <BorderLinearProgress
              variant="determinate"
              value={
                isAdspendBalanceSuccess && adspendProgressValue
                  ? Number(adspendProgressValue)
                  : 0
              }
            />
            <div>
              <span>
                {user.user.data.currency
                  ? String(user.user.data.currency).toLowerCase() === "" ||
                    String(user.user.data.currency).toLowerCase() === null
                    ? "₹"
                    : fetchSymbolFromCurrency(user.user.data.currency)
                  : "₹"}{" "}
                {isAdspendBalanceLoading
                  ? "Loading..."
                  : isAdspendBalanceSuccess
                  ? adspendBalance.spend.toLocaleString()
                  : "0"}{" "}
                spent
              </span>{" "}
              |{" "}
              <span>
                {user.user.data.currency
                  ? String(user.user.data.currency).toLowerCase() === "" ||
                    String(user.user.data.currency).toLowerCase() === null
                    ? "₹"
                    : fetchSymbolFromCurrency(user.user.data.currency)
                  : "₹"}{" "}
                {isAdspendBalanceLoading
                  ? "Loading..."
                  : isAdspendBalanceSuccess
                  ? adspendBalance.spend_cap.toLocaleString()
                  : "0"}{" "}
                spending limit
              </span>
            </div>
          </div>
        ) : (
          <NoAccountSpendingLimit>
            <span>No Account spending limit found.</span>
          </NoAccountSpendingLimit>
        )}
      </AccountSpendingLimit>

      <MyPlan>
        <h4>My plan</h4>
        <div>
          <div>
            <img src={PlanImg} alt="plan-img" />
            <div>
              <h6>
                {isError || data === null
                  ? "No Active Plans"
                  : new Date() > new Date(data?.expires_date)
                  ? "No Active Plans"
                  : "Monthly Plan"}
              </h6>
              {isError || data === null ? (
                <p>Buy plan to get access</p>
              ) : new Date() > new Date(data?.expires_date) ? (
                <p>Buy plan to get access</p>
              ) : (
                <p>
                  Next payment on{" "}
                  {isLoading
                    ? "Loading..."
                    : isSuccess
                    ? format(parseISO(data?.expires_date), "do MMMM, yyyy")
                    : ""}
                </p>
              )}
            </div>
          </div>
          {isError || data === null ? (
            <Link to="/subscription">Explore Plans</Link>
          ) : new Date() > new Date(data?.expires_date) ? (
            <Link to="/subscription">Explore Plans</Link>
          ) : (
            <PlanPriceContainer>
              <span>
                $
                {isLoading
                  ? "Loading..."
                  : isSuccess
                  ? data !== null
                    ? data.usd_amount
                    : "0"
                  : "0"}{" "}
                per month
              </span>
              <span>
                Ads spend limit: $
                {isLoading
                  ? "Loading..."
                  : isSuccess
                  ? data !== null
                    ? data.usd_price_range
                    : "0"
                  : "0"}
              </span>
            </PlanPriceContainer>
          )}
        </div>
      </MyPlan>

      {/* <Notification>
        <h4>Notification settings</h4>
        <div>
          <div>
            <h6>Receive email notification</h6>
            <p>You will receive email notification from us</p>
          </div>
          <AntSwitch
            checked={switcher}
            onClick={() => setSwitcher(!switcher)}
            inputProps={{ "aria-label": "ant design" }}
          />
        </div>
      </Notification> */}

      <BillingModal
        open={showBillingModal}
        onClose={() => setShowBillingModal(false)}
      >
        <BillingModalBox>
          <div>
            <img
              src={CrossCircleImg}
              alt="cross-circle-icon"
              onClick={() => setShowBillingModal(false)}
            />
          </div>
          <div>
            <h2>Billing Information</h2>
            <hr />
            <InputTextField
              label="GST Number"
              variant="outlined"
              name="gstNo"
              value={billingData.gstNo}
              onChange={handleChange}
              placeholder="22FBZLP0864P1Z9"
              active={Boolean(billingData.gstNo)}
            />
            <InputTextField
              label="Street Address"
              variant="outlined"
              type="text"
              name="streetAddress"
              value={billingData.streetAddress}
              onChange={handleChange}
              placeholder="No4, uthamar gandhi salai, Workafella"
              active={Boolean(billingData.streetAddress)}
            />
            <DisplayFlexContainer>
              <InputTextField
                label="City"
                variant="outlined"
                active={Boolean(billingData.city)}
                type="text"
                name="city"
                value={billingData.city}
                onChange={handleChange}
                placeholder="Chennai"
              />
              <InputTextField
                label="State"
                variant="outlined"
                active={Boolean(billingData.state)}
                type="text"
                name="state"
                value={billingData.state}
                onChange={handleChange}
                placeholder="Tamilnadu"
              />
              <InputTextField
                label="Zip code"
                variant="outlined"
                active={Boolean(billingData.zipCode)}
                type="text"
                name="zipCode"
                value={billingData.zipCode}
                onChange={handleChange}
                placeholder="600023"
                className="billing_input"
              />
            </DisplayFlexContainer>
            <InputTextField
              label="Contact Number"
              variant="outlined"
              name="contact"
              value={billingData.contact}
              onChange={handleChange}
              placeholder="9999999999"
              active={Boolean(billingData.contact)}
            />
            <button onClick={handleUpdateBillingDetails}>Save</button>
          </div>
        </BillingModalBox>
      </BillingModal>

      <ChangeAccountDetailsModal
        open={showChangeAccountDetailsModal}
        onClose={() => setShowChangeAccountDetailsModal(false)}
      >
        <ChangeAccountDetailsModalBox>
          <div>
            <img
              src={CrossCircleImg}
              alt="cross-circle-icon"
              onClick={() => setShowChangeAccountDetailsModal(false)}
            />
          </div>
          <div>
            <h2>Change Account details</h2>
            <hr />
            <ProfileContainer>
              <div>
                <img src={ProfileImg} alt="profile-img" />
              </div>
              <div>
                <div>
                  <input
                    ref={nameref}
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    disabled={!isInputEditable}
                    autoFocus
                    autoComplete="off"
                  />
                  {isInputEditable ? (
                    <button onClick={handleSaveName}>Save</button>
                  ) : (
                    <button onClick={() => setIsInputEditable(true)}>
                      Change name
                    </button>
                  )}
                </div>
                <p>{user.loading ? "Loading..." : user?.user.data?.email}</p>
              </div>
            </ProfileContainer>
            <FBAdPage
              setShowChangeAccountDetailsModal={
                setShowChangeAccountDetailsModal
              }
            />
          </div>
        </ChangeAccountDetailsModalBox>
      </ChangeAccountDetailsModal>
    </SettingsContainer>
  );
};

export default Settings;

// const AntSwitch = styled(Switch)(({ theme }) => ({
//   width: 60,
//   height: 32,
//   padding: 0,
//   borderRadius: "40px",
//   display: "flex",
//   alignItems: "center",
//   "&:active": {
//     "& .MuiSwitch-thumb": {
//       width: 15,
//     },
//     "& .MuiSwitch-switchBase.Mui-checked": {
//       transform: "translateX(9px)",
//     },
//   },
//   "& .MuiSwitch-switchBase": {
//     padding: 2,
//     marginTop: "2px",
//     "&.Mui-checked": {
//       transform: "translateX(32px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         opacity: 1,
//         backgroundColor:
//           theme.palette.mode === "dark"
//             ? "rgba(15, 110, 255, 1) !important"
//             : "rgba(15, 110, 255, 1) !important",
//       },
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
//     width: 24,
//     height: 24,
//     borderRadius: "50%",
//     transition: theme.transitions.create(["width"], {
//       duration: 200,
//     }),
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 16 / 2,
//     opacity: 1,
//     boxSizing: "border-box",
//     backgroundColor: "rgba(15, 110, 255, 1)",
//   },
// }));

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    // backgroundColor: theme.palette.mode === "light" ? "yellow" : "orange",
    backgroundColor:
      value && value > 90
        ? "red"
        : value && value >= 75 && value <= 90
        ? "orange"
        : "blue",
    // background: "linear-gradient(to right, #0193FF, #0168FF)",
  },
}));
